import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import apiFormClient from '../../../services/apiForm';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";
import { initPixelTrabalheConosco } from '../../../services/facebookPixel';
import { logPixelPageView } from '../../../services/facebookPixel';
import { initPixelConvTrkr } from '../../../services/facebookPixel';
import { initGA } from '../../../services/googleAnalytics';
import { IoIosArrowForward } from "react-icons/io";
import { Link } from "react-router-dom";

const StyledBodyOneTC = styled.div`

[type="file"] {
    height: 0;
    overflow: hidden;
    width: 0;
  }
  
  [type="file"] + label {
      width:20%;
    background: #f15d22;
    border: none;
    border-radius: 10px;
    color: #fff;
    cursor: pointer;
    font-weight: 600;
    outline: none;
    padding: 2%;
    position: relative;
    transition: all 0.3s;
    
    &:hover {
      background-color:(#f15d22, 10%);
    }
    
    &.btn-1 {
      background-color: #ff6e0c;
      box-shadow: 0 6px #ff5415;

  
      &:hover {
        box-shadow: 0 4px darken(#f79159, 10%);
        top: 3px;
      }
    }
  }
.tituloTutorial{
    padding-top:1%;
    margin:3% 10% 0px 10%;
    display:flex;
    flex-direction: column;
    position:sticky;
}
.tituloTutorial h1{
    margin-bottom:2%;
    font-size:2.5vw;
    margin-right: auto;
}
.h1Subli{
    background-color:#ff5019;
    width:35.2vw;
    height:5px;
    margin-top:-2%;
    margin-bottom:2%;
}
.divForm {
   
}
.divForm form{
    display: flex;
    align-items: left;
    flex-direction: row;
    margin: 0px 0px 0px 0px;
    height: auto;
    width: 100%;
    font-size: 1.4vw;
}
.divForm input{
    width:80%;
    background-color:#f1f1f1;
    box-shadow: 0 0 0 0;
    border: 0 none;
    outline: 0;
    
}
.formTwo input:nth-child(8){
    width:100%;
}
.divForm label{
  margin-top:1%;
}
.btn-enviarTC{
    font-size:1.5vw;
    margin:4% auto 5% auto;
    color: #f05123;
    font-weight: 600;
    border-radius: 20px ;
    width: 15vw;
    height: 7vh;
    cursor:pointer;
}
.formOne{
  display: flex;
  flex-direction:column;
  width:56%;
}
.formTwo{
    display: flex;
    width:44%;
    height:auto;
    flex-direction:column;
}
.formTwo h4{
    font-size:1.5vw;
    left:52.5vw;
    top:13vw;
    position:absolute
}
.anexar{
    display: ${({ resumeConfirm }) => resumeConfirm ? 'none' : ''} !important;
}
.anexado{
    display: ${({ resumeConfirm }) => resumeConfirm ? '' : 'none'} !important;
}
.tamTextArea{
    height:40%;
    width:100%;
    background-color:#f1f1f1;
    border:none;
}
.tituloTutorial p{
    font-size:1vw;
    margin-top:1%;
}

img {
    max-width: 100%;
}

iframe {
    min-width: 50%;
    height: 40vh;
}

@media only screen and (max-width: 800px){
    [type="file"] + label {
     width:25vw;
     
    }

    .divForm input {
      width: 100%;
  }

    .tituloTutorial h1{
        margin-bottom:2%;
        font-size:7vw;
        margin-right: auto;
        text-align:center;
    }
    .h1Subli{
        background-color:#ff5019;
        width:35.2vw;
        height:5px;
        margin:-2% auto auto auto;
    }
    .divForm form{
        display: flex;
        align-items: left;
        flex-direction: column;
        margin: 2% 0px 0px 0px;
        height: auto;
        width: 100%;
        font-size: 5vw;
    }
    .formOne{
        display: flex;
        flex-direction:column;
        width:100%;
      }
      .formTwo{
          display: flex;
          width:100%;
          height:auto;
          flex-direction:column;
      }
      .btn-enviarTC{
        font-size:4vw;
        margin:8% auto 5% auto;
        border-radius: 20px;
        width: 30vw;
        height: 6vh;
    }
    .formTwo h4{
        font-size:4vw;
        left:27vw;
        top:119vw;
        position:absolute;
    }
    .tituloTutorial p{
        font-size:3vw;
        margin-top:5%;
    }
    .tamTextArea{
        height:40%;
        width:100%;
        background-color:#f1f1f1;
        border:none;
    }
    .formTwo input:nth-child(8){
        width:80%;
    }
}

.tutoriais  {
    h3 {
        margin-bottom: 40px;
    }

    p, a {
        color: #181818;
        margin-top: 4px;
        font-size: 20px;
        font-weight: 500;
        text-decoration: none;
    }

    a {
        p {
            color: #ff5019 !important;    
        }
     
        color: #ff5019 !important;
    }

    input{
        widtth: 100% !important;
        padding: 5px;
        border: 1px solid #959595;
        border-radius: 5px;
        font-size: 20px;
    }
}
`;


const TutorialList = ({ ...rest }) => {

    const [groups, setGroups] = useState([]);
    const [allGroups, setAllGroups] = useState([]);
    const [tutorials, setTutorials] = useState({});
    const [pesquisaText, setPesquisaText] = useState("");

    const filterAllTutiriais = () => {
        const toastId = toast.loading("Buscando tutoriais...");
        apiFormClient.get('get_all_groups?company=datamais')
            .then(response => {
                const groupsData = response.data;
                if (!groupsData || groupsData.length === 0) {
                    toast.dismiss(toastId);
                    console.log("No groups data found");
                    return;
                }

                apiFormClient.get('get_all_tutorials?company=datamais')
                    .then(tutorialsResponse => {
                        const tutorialsData = tutorialsResponse.data;
                        const tutorialsMap = tutorialsData.reduce((acc, tutorial) => {
                            if (!acc[tutorial.ID_DM001]) {
                                acc[tutorial.ID_DM001] = [];
                            }
                            acc[tutorial.ID_DM001].push(tutorial);
                            return acc;
                        }, {});

                        const groupsMap = groupsData.reduce((acc, group) => {
                            acc[group.ID_DM001] = { ...group, children: [] };
                            return acc;
                        }, {});

                        Object.values(groupsMap).forEach(group => {
                            if (group.ID_DM001_PAI !== 0 && groupsMap[group.ID_DM001_PAI]) {
                                groupsMap[group.ID_DM001_PAI].children.push(group);
                            }
                        });

                        const topLevelGroups = Object.values(groupsMap).filter(group => 
                            group.ID_DM001_PAI === 0 && (tutorialsMap[group.ID_DM001] || group.children.some(child => tutorialsMap[child.ID_DM001]))
                        );

                        topLevelGroups.sort((a, b) => a.NR_ORDEM - b.NR_ORDEM);

                        setAllGroups(topLevelGroups);
                        setGroups(topLevelGroups);
                        setTutorials(tutorialsMap);
                        toast.dismiss(toastId);
                    })
                    .catch(error => console.log("Error fetching tutorials data", error), toast.dismiss(toastId));
            })
            .catch(error => {
                toast.dismiss(toastId);
                console.log("Error fetching groups data", error);
            });
    }

    useEffect(() => {
        filterAllTutiriais();
    }, []);

    const handleSearch = (event) => {
        if (event.key === 'Enter') {
            if (!pesquisaText) {
                filterAllTutiriais();
            } else {
                apiFormClient.post('filter_tutorials?company=datamais', {
                    DS_PESQUISA_TUTORIAL: pesquisaText
                })
                    .then(response => {
                        const filteredTutorials = response.data;
                        const tutorialsMap = {};
                        const filteredGroupIds = new Set();

                        filteredTutorials.forEach(tutorial => {
                            if (!tutorialsMap[tutorial.ID_DM001]) {
                                tutorialsMap[tutorial.ID_DM001] = [];
                            }
                            tutorialsMap[tutorial.ID_DM001].push(tutorial);
                            filteredGroupIds.add(tutorial.ID_DM001);
                        });

                        setTutorials(tutorialsMap);

                        const filterSubgroups = (subgroups) => {
                            return subgroups.filter(child =>
                                filteredGroupIds.has(child.ID_DM001) ||
                                (child.children && child.children.length > 0 && filterSubgroups(child.children).length > 0)
                            );
                        };

                        const filteredGroups = allGroups.map(group => {
                            const filteredChildren = filterSubgroups(group.children);
                            return filteredGroupIds.has(group.ID_DM001) || filteredChildren.length > 0
                                ? { ...group, children: filteredChildren }
                                : null;
                        }).filter(group => group !== null);

                        setGroups(filteredGroups);
                    })
                    .catch(error => {
                        console.log(error);
                    });
            }
        }
    };

    const renderGroupList = (groupList) => {
        return groupList.map(group => {
            if (tutorials[group.ID_DM001] || group.children.some(child => tutorials[child.ID_DM001])) {
                return (
                    <div key={group.ID_DM001} style={{ marginBottom: "15px" }}>
                        <p style={{ margin: "0px" }}>- {group.NM_GRUPO}</p>
                        {tutorials[group.ID_DM001]?.map(tutorial => (
                            <Link to={`./Tutorial/${tutorial.ID_DM002}/${tutorial.DS_TITULO_TUTORIAL.replace('/', '')}`}>
                                <p key={tutorial.ID_DM002}> <IoIosArrowForward /> {tutorial.DS_TITULO_TUTORIAL}</p>
                            </Link>
                        ))}
                        {group.children && group.children.length > 0 && (
                            <div style={{ marginLeft: '20px' }}>
                                {renderGroupList(group.children)}
                            </div>
                        )}
                    </div>
                );
            }
            return null;
        });
    };

    const midIndex = Math.ceil(groups.length / 2);
    const firstColGroups = groups.slice(0, midIndex);
    const secondColGroups = groups.slice(midIndex);

    return (
        <StyledBodyOneTC >
            <div className='tituloTutorial'>
                <h1>Tutoriais</h1>
                <></>
                <div className='h1Subli' />
                <div className='container tutoriais'>
                    <br/>
                    <p style={{ textAlign: 'left' }}><a href="../../../admin" target='_blank' style={{ fontSize: '18px' }}>Acesso à Área Restrita</a></p>
                    <div className='formOne' style={{ width: '100%' }}>
                        <input
                            placeholder='Pesquisar dúvida'
                            onChange={(e) => setPesquisaText(e.target.value)}
                            onKeyDown={handleSearch}
                            className='inputname'
                        />
                    </div>
                    <br />
                    <div className="tutoriaisList" style={{ display: 'flex', flexDirection: 'row' }}>
                        <div style={{ flex: 1, marginRight: '20px' }}>
                            {renderGroupList(firstColGroups)}
                        </div>
                        <div style={{ flex: 1 }}>
                            {renderGroupList(secondColGroups)}
                        </div>
                    </div>
                </div>

                <ToastContainer />
            </div>
        </StyledBodyOneTC>
    )
}

export default TutorialList;
